(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/fraction-payment-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/fraction-payment-reducer.js');
"use strict";

const {
  createReducer
} = RTK;
const {
  payFraction
} = svs.components.marketplaceData.fractionPayment.actions;
const {
  reducerStates
} = svs.components.marketplaceData.constants;
const initialState = {
  fractionPurchaseState: reducerStates.PASSIVE
};
const fractionPaymentReducer = createReducer(initialState, builder => {
  builder.addCase(payFraction.pending, (state, action) => {
    var _action$meta;
    state.fractionPurchaseState = reducerStates.LOADING;
    state.containerId = action === null || action === void 0 || (_action$meta = action.meta) === null || _action$meta === void 0 || (_action$meta = _action$meta.arg) === null || _action$meta === void 0 || (_action$meta = _action$meta.fractionData) === null || _action$meta === void 0 ? void 0 : _action$meta.containerId;
  }).addCase(payFraction.fulfilled, (state, action) => {
    state.fractionPurchaseState = reducerStates.COMPLETED;
  }).addCase(payFraction.rejected, (state, action) => {
    state.fractionPurchaseState = reducerStates.ERRORED;
    state.error = action.error;
  });
});
setGlobal('svs.components.marketplaceData.store.reducers.fractionPaymentReducer', fractionPaymentReducer);

 })(window);